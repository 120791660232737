import { PersistentInfoProvider } from '@/core/tracking/pico/providers';
import {
  attributionParamKeys,
  FBPaymentCompletedInterceptor,
  FBProcessingCompletedInterceptor,
  persistAttrbution,
} from '@/core/tracking/pico/attribution';
import { queryParamsAllowList } from '@/core/tracking/pico/attribution/landingQueryParams/landingQueryAllowList';
import { EventInterceptor } from '../event';
import { filterLandingQueryParams } from './landingQueryParams/landingQueryParamsFilter';

const paramsToPersist = queryParamsAllowList;

const getPageUrl = () => document.URL.split('?')[0];

type CreateAttributionManagerProps = {
  persistentInfoProvider: PersistentInfoProvider;
};

export type AttributionManager = {
  getLandingUrl: () => string;
  /**
   * Use this method to get a filtered queryParamsObject
   */
  getLandingQueryParams: () => Record<string, string>;
  /**
   * This method must return a JSON.stringify version
   * of the unfiltered landing query parameters
   */
  getLandingQueryParamsJson: () => string;
  getAttributionInterceptors: () => EventInterceptor[];
};

export const createAttributionManager = ({
  persistentInfoProvider,
}: CreateAttributionManagerProps): AttributionManager => {
  const _landingUrl = getPageUrl();
  const landingSearchParams = new URLSearchParams(window.location.search);
  const _initialParams = Object.fromEntries(landingSearchParams);
  persistAttrbution({
    paramsFound: _initialParams,
    attributionParamKeys,
    persistentInfoProvider,
  });
  for (const param of paramsToPersist) {
    if (_initialParams[param]) {
      persistentInfoProvider.setInfo(param, _initialParams[param]);
    }
  }
  const _filteredLandingParams = filterLandingQueryParams(_initialParams);

  return {
    getLandingUrl() {
      return _landingUrl;
    },
    getLandingQueryParams() {
      return _filteredLandingParams;
    },
    getLandingQueryParamsJson() {
      return JSON.stringify(_initialParams);
    },
    getAttributionInterceptors() {
      return [FBPaymentCompletedInterceptor, FBProcessingCompletedInterceptor];
    },
  };
};
