import { AttributionManager } from '../attribution/attributionManager';

enum DeviceType {
  MOBILE = 'mobile',
  TABLET = 'tablet',
  DESKTOP = 'desktop',
}

export type Experiments = { [key: string]: any };
export type Settings = { [key: string]: any };

export type UserMetadataProvider = {
  getUserCountry?: () => string;
  getUserLocale: () => string;
  getDeviceType: () => string;
  getBrowser: () => string | undefined;
  getLandingUrl: () => string;
  getLandingQueryParams: () => Record<string, string>;
  getLandingQueryParamsJson: () => string;
  getCreatedAt: () => string | undefined;
  setCreatedAt: (date: Date) => void;
  getUserAgent: () => string;
  getSettings: () => { [key: string]: any };
  setSettings: (settings: Settings) => void;
  getIsFree: () => boolean;
  setIsFree: (isFree: boolean) => void;
  getExperiments: () => { [key: string]: any };
  setExperiments: (experiments: Experiments) => void;
};

export const createUserMetadataProvider = ({
  attributionManager,
}: {
  attributionManager: AttributionManager;
}): UserMetadataProvider => {
  let _createdAt: Date;
  let _settings: { [key: string]: any };
  let _experiments: { [key: string]: any };
  let _isFree: boolean;
  return {
    setCreatedAt(date: Date) {
      _createdAt = date;
    },
    setSettings(settings: { [key: string]: any }) {
      _settings = settings;
    },
    setExperiments(experiments: { [key: string]: any }) {
      _experiments = experiments;
    },
    setIsFree(isFree: boolean) {
      _isFree = isFree;
    },
    getIsFree(): boolean {
      return _isFree;
    },
    getSettings(): Settings {
      return _settings;
    },
    getExperiments(): Experiments {
      return _experiments;
    },
    getCreatedAt(): string | undefined {
      const dateString =
        _createdAt &&
        !Number.isNaN(_createdAt.getTime()) &&
        _createdAt.toISOString();
      if (dateString) return dateString;
    },
    getLandingUrl() {
      return attributionManager.getLandingUrl();
    },
    getLandingQueryParams() {
      return attributionManager.getLandingQueryParams();
    },
    getLandingQueryParamsJson() {
      return attributionManager.getLandingQueryParamsJson();
    },
    getUserLocale() {
      return Intl.DateTimeFormat().resolvedOptions().locale;
    },
    getDeviceType(): DeviceType {
      const { userAgent } = navigator;
      const isTablet = /(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(
        userAgent
      );
      if (isTablet) return DeviceType.TABLET;
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        );
      return isMobile ? DeviceType.MOBILE : DeviceType.DESKTOP;
    },
    getUserAgent() {
      return navigator.userAgent;
    },
    getBrowser(): string | undefined {
      const ua = navigator.userAgent.toLowerCase();

      // Social-app browsers
      if (/instagram/.test(ua)) return 'instagram';
      if (/fban|fbav/.test(ua)) return 'facebook';

      // Standard browsers - order matters
      if (/edg\/|edge\//.test(ua)) return 'edge';
      if (/opr\/|opera\//.test(ua)) return 'opera';
      if (/chrome|crios/.test(ua)) return 'chrome';
      if (/firefox|fxios/.test(ua)) return 'firefox';
      if (/safari/.test(ua)) return 'safari';

      return undefined;
    },
  };
};
