// List here all the parameters that must be kept in Pico landing_page_info.
// You must list only the query parameters keys.
// All the parameters not in this list will not be sent to Pico as landing_page_info.
// Please keep this list in alphabetical order to easy spot duplicated, unwanted parameters.
export const queryParamsAllowList = [
  'adgroup',
  'campaign',
  'creative',
  'fbc',
  'fbclid',
  'fbp',
  'gbraid',
  'gclid',
  'google_network',
  'google_placement',
  'match_type',
  'msclkid',
  'msclid',
  't_agid',
  't_agname',
  't_cid',
  't_cname',
  't_crid',
  't_crname',
  't_device',
  't_gcid',
  't_match_type',
  't_network',
  't_s',
  't_validation',
  'ttclid',
  'utm_campaign',
  'utm_content',
  'utm_medium',
  'utm_source',
  'utm_term',
  'v', // virality parameters (user share)
  'wbraid',
] as const;
